// src/components/Loading.jsx
import React from "react";
import { Spin } from "antd"; // Assuming you're using Ant Design for the spinner
import "./Loading.css"; // Create this CSS file for the blur effect

const RouterLoader = () => {
  return (
    <div className="loading-container">
      <div className="spinner">
        <Spin size="large" />
      </div>
    </div>
  );
};

export default RouterLoader;
