import React, { useState, useCallback } from "react";
import "./layout.css";
import SideBar from "./sidebar";
import TopBanner from "./topbanner";

export default function Layout({ children }) {
    return (
        <div className="app-container">
            <div className="top-banner">
                <TopBanner />
            </div>
            <div className="render-section">
                <div className="left-section">
                    <SideBar />
                </div>
                <div className="right-section">
                    {children}
                </div>
            </div>
        </div>
    );
}
