import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Layout from "pages/layout/layout";
import RouterLoader from "./components/RouterLoader"

const SettingPageThree = React.lazy(() => import("pages/SettingPageThree"));
const SettingPageTwo = React.lazy(() => import("pages/SettingPageTwo"));
const SettingPageOne = React.lazy(() => import("pages/SettingPageOne"));
const Services = React.lazy(() => import("pages/Services"));
const Loan = React.lazy(() => import("pages/Loan"));
const CreditCards = React.lazy(() => import("pages/CreditCards"));
const Investments = React.lazy(() => import("pages/Investments"));
const Accounts = React.lazy(() => import("pages/Accounts"));
const Transaction = React.lazy(() => import("pages/Transaction"));
const MainDashboard = React.lazy(() => import("pages/MainDashboard"));
const Login = React.lazy(() => import("pages/Login/index"));
const Beneficiary = React.lazy(() => import("pages/Beneficiary"));
const AddBeneficiary = React.lazy(() => import("pages/Beneficiary/addBeneficiary"));
const DynamicForm = React.lazy(() => import("pages/DynamicForm"));
const Registration = React.lazy(() => import("pages/Registration"));
const Transactions = React.lazy(() => import("pages/Transaction/createtranaction"));
const SelfTransfers = React.lazy(() => import("pages/Transaction/selfTransfer"))
const Statement = React.lazy(() => import("pages/Statement"));
const LocationAtmBranch = React.lazy(() => import("pages/LocationAtmBranch"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const NeftTransfer = React.lazy(() => import("pages/Transaction/neft"))
const FAQs = React.lazy(() => import("pages/FQAs"));
const RtgsTransfer = React.lazy(() => import("pages/Transaction/rtgs"));
const ForeignRemittance = React.lazy(() => import("pages/Transaction/foreignRemittance"))
const LoanApplication = React.lazy(() => import("pages/Loans/loanapplication"));
const ScheduledTransfers = React.lazy(() => import("pages/Transaction/scheduleTransfer"))
const DashboardV2 = React.lazy(() => import("pages/Dashboard/dashboardv2"));
const TransactionHistory = React.lazy(() => import("pages/Transaction/transactionsHistory"))
const Kyc = React.lazy(() => import("pages/Kyc"))
const Support = React.lazy(() => import("pages/support"))
const Request = React.lazy(() => import("pages/cheque"))
const Calculator = React.lazy(() => import("pages/Calculator"))
const Cheque = React.lazy(()=> import("pages/cheque"));
const Changepassword = React.lazy(()=> import("pages/changepassword"));
const Transationlimit = React.lazy(()=> import("pages/transactionlimit"));
const LoanApp = React.lazy(()=> import("pages/Loans/allloanApplication"));
const TermAndCondtion = React.lazy(()=> import("pages/TermAndCondtion"));
const ViewFdRate = React.lazy(()=> import("pages/Investments/viewfdrate"));


const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<RouterLoader />}>
      <Router>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/maindashboard" element={<MainDashboard />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/investments" element={<Layout><Investments /></Layout>} />
          <Route path="/creditcards" element={<CreditCards />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/services" element={<Services />} />
          <Route path="/settingpageone" element={<Layout><SettingPageOne /></Layout>} />
          <Route path="/settingpagetwo" element={<SettingPageTwo />} />
          <Route path="/settingpagethree" element={<SettingPageThree />} />
          <Route path="/beneficiary" element={<Layout><Beneficiary /></Layout>} />
          <Route path="/dynamicform" element={<DynamicForm />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/addbeneficiary" element={<AddBeneficiary />} />
          <Route path="/createtransaction" element={<Layout><Transactions /></Layout>} />
          <Route path="/selftranfer" element={<SelfTransfers />} />
          <Route path="/statement" element={<Layout><Statement /></Layout>} />
          <Route path="/locationatmbranch" element={<LocationAtmBranch />} />
          <Route path="/neft" element={<NeftTransfer />} />
          {/* <Route path="/dashboard" element={<Layout>  <Dashboard /></Layout>} /> */}
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/rtgs" element={<RtgsTransfer />} />
          <Route path="/foreignremittance" element={<ForeignRemittance />} />
          <Route path="/rtgs" element={<RtgsTransfer />} />
          <Route path="/loanapplication" element={<Layout><LoanApplication /></Layout>} />
          <Route path="/scheduletransfers" element={<ScheduledTransfers />} />
          <Route path="/dashboard" element={<Layout><DashboardV2 /></Layout>} />
          <Route path="/transactinshistory" element={<TransactionHistory />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/support" element={<Layout><Support /></Layout>} />
          <Route path="/request" element={<Layout><Request /></Layout>} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/cheque" element={<Layout> <Cheque/> </Layout>}/> 
           <Route path="/changepassword" element={<Layout> <Changepassword/> </Layout>}/> 
           <Route path="/transactionlimit" element={<Layout> <Transationlimit/> </Layout>}/>
           <Route path="/loanapp" element={ <LoanApp/>}/>
           <Route path="/termandcondition" element={ <TermAndCondtion/>}/>
           <Route path="/viewfdrate" element={ <ViewFdRate/> }/>
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
